//import { StyleSheet } from 'react-native';
// import { StyleSheet, Text, View ,ColorValue} from 'react-native';
// import React,{useState,useEffect,useRef,cloneElement} from 'react';
 import { StyleSheet, Button, SafeAreaView,TextInput, Alert,Image } from 'react-native';
// import Axios from 'axios';
// import QRCode from 'qrcode';
// import QrReader from 'react-qr-reader';
// import {SelectList} from 'react-native-dropdown-select-list';
// import Select from 'react-select';
// import {CheckBox} from 'react-native-elements';
// //import {RadioButton} from 'react-native-elements';
import React,{useState,useEffect,useRef,cloneElement} from 'react';
//import { StyleSheet, Button, SafeAreaView,TextInput, Alert,Image } from 'react-native';
import { Container, Card, CardContent, makeStyles, Grid, TextField } from '@mui/material';
import QRCode from 'qrcode';
import  {QrReader}  from 'react-qr-reader';
import Axios from 'axios';
import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import {SelectList} from 'react-native-dropdown-select-list';
import Select from 'react-select';
import {CheckBox} from 'react-native-elements';
// import EditScreenInfo from '../components/EditScreenInfo';
// import { Text, View } from '../components/Themed';
//import { Button} from 'react-native-elements';
import { red } from '@mui/material/colors';
import { color } from 'react-native-elements/dist/helpers';
// import { Container, Card, CardContent, makeStyles, Grid, TextField } from '@mui/material';
export default function TabTwoScreen() {
//   const den = () => {
//     var d=new Date();
//     var days=['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
//     var months=['01','02','03','04','05','06','07','08','09','10','11','12'];
//     alert(
//       days[d.getDay()]+','+d.getDate()+"/"
//       +months[d.getMonth()]+"/"
//       +d.getFullYear()+","
//       +'hh:'+d.getHours()+'mins'
//       +d.getMinutes()+':sec'
//       +d.getSeconds()+':msec'
//       +d.getMilliseconds())
//     }
// return(
// <View style={styles.title}>
// <Text></Text>
// <Text></Text>
// <Text></Text>
// <Text></Text>
// <Text></Text>
//     <Text>
//             Hai User,</Text>
//     <Text>                  Click on the ToggleButton enjoy the Today's Date ,</Text>
//      <Text>special day using Customers mobile App available Hour,Minute,Milliseconds...
//      </Text>
//      <Text></Text>
//     <Text></Text>
//      <Text></Text>
//      <Text></Text>
//      <Text></Text>
//   <Button
//   title="Expected Click"
//   onPress={den}
//   />
//   </View>
// )
//
// }
//
// const styles = StyleSheet.create({
//   container: {
//     top:880,
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor:'brown',
//     fontSize:30,
//
//   },
//   title: {
//     fontSize: 40,
//     fontWeight: 'bold',
//     backgroundColor:'yellow',
//     fontStyle:'italic',
//           },
//   separator: {
//     marginVertical: 30,
//     height: 1,
//     width: '80%',
//   },
// });
// const TabTwoScreen = () => {
//}
//import logo from './logo.svg';
//import './App.css';
// import React, { useState, useRef } from 'react';
// import { Container, Card, CardContent, makeStyles, Grid, TextField, Button } from '@material-ui/core';
// import QRCode from 'qrcode';
// import  QrReader  from 'react-qr-reader';

// function TabTwoScreen() {
//   const [text, setText] = useState('');
//   const [imageUrl, setImageUrl] = useState('');
//   const [scanResultFile, setScanResultFile] = useState('');
//   const [scanResultWebCam, setScanResultWebCam] = useState('');
//   const classes = useStyles();
//   const qrRef = useRef(null);

  // const generateQrCode = async () => {
  //   try {
  //     const response = await QRCode.toDataURL(text);
  //     setImageUrl(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const handleErrorFile = (error) => {
  //   console.log(error);
  // };
//   const handleScanFile = (result) => {
//     if (result) {
//       setScanResultFile(result);
//     }
//   };
//   const onScanFile = () => {
//     console.log(imageUrl, 'imageUrl');
//     console.log(qrRef);
//     //open file dialog
//     // qrRef.current.click();
//     qrRef?.current?.openImageDialog();
//       };
//   const handleErrorWebCam = (error) => {
//     console.log(error);
//   };
//   const handleScanWebCam = (result) => {
//     if (result) {
//       setScanResultWebCam(result);
//     }
//   };
//   return (
//     <Container className={classes.container}>
//       <Card>
//         {/* <h2 className={classes.title}>Generate Download & Scan QR Code with React js</h2> */}
//         <h2 className={classes.title}>Download for Query Shop Details </h2>
//         <CardContent>
//           {/* <Grid container spacing={2}>
//                   <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
//               <a><TextField label="ENTER QRCODE SITE" onChange={(e) => setText(e.target.value)} /></a>
//               <a><Button className={classes.btn} variant="contained" color="primary" onClick={() => generateQrCode()}>
//                 Generate
//               </Button></a>
//               <br />
//               <br />
//               <br />
//               {imageUrl ? (
//                 <a href={imageUrl} download>
//                   <img src={imageUrl} alt="img" />
//                 </a>
//               ) : null}
//             </Grid> */}
//             {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
//             <input type="file" id="file" ref={qrRef} style={{ display: 'none' }} />          
//             <a><Button className={classes.btn}  variant="contained" color="default" onClick={onScanFile}>
//                 Scan Qr Code
//               </Button></a>
//         <QrReader ref={qrRef} delay={300} style={{ width: '100%' }} onError={handleErrorFile} onScan={handleScanFile} legacyMode />
//               <h3>Scanned Code: {scanResultFile}</h3>
//             </Grid> */}
//             {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
//               <h3>QrCode Scan by Web Cam</h3>
//               <QrReader delay={300} style={{ width: '100%' }} onError={handleErrorWebCam} onScan={handleScanWebCam} />
//               <h3>Scanned WebCam Code: {scanResultWebCam}</h3>
//             </Grid>
//           {/* </Grid> */}
//         </CardContent>
//       </Card>
//     </Container>
//   );
// }

// const useStyles = makeStyles((theme) => ({
//   container: {
//     marginTop: 10,
//   },
//   title: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     background: '#3f51b5',
//     color: '#fff',
//     padding: 20,
//   },
//   btn: {
//     marginTop: 10,
//     marginBottom: 20,
//   },
// }));
// export default TabTwoScreen; */}
//   return (
//     <View style={styles.container}>
//       <Text style={styles.title}>Tab One</Text>
//       <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
//       <EditScreenInfo path="welcome to india" />
//     </View>
//   );



// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor:'light cyan'
//   },
//   title: {
//     fontSize: 20,
//     fontWeight: 'bold',
//   },
//   separator: {
//     marginVertical: 30,
//     height: 1,
//     width: '80%',
//   },
// });
//export default function TabTwoScreen() {
  //

//}

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor:'brown'
//   },
//   title: {
//     fontSize: 50,
//     fontWeight: 'bold',
//   },
//   separator: {
//     marginVertical: 30,
//     height: 1,
//     width: '80%',
//   },
// });
// const TabOneScreen = () => {
  const [textFieldValue, setTextFieldValue] = useState('');
  const state = {

         email:'',
    password:''
  };

  const [text, onChangeText] = React.useState("");
  const [number, onChangeNumber] = React.useState(null);
//  const [email,setEmail] =React.useState('')
const [interestRates,setInterestRates]=React.useState('');
const [principle,setPrinciple]=React.useState('');
const [principles,setPrinciples]=React.useState('');
const [nooftimesinterestcompoundperyear,setNooftimesinterestcompoundperyear]=React.useState('');
const [amounts,setAmounts]=React.useState('');
const [compoundInterest,setCompoundInterest]=React.useState('');
  const[food,setFoodList] =React.useState([])
//const [password, setPassword]= useState('');
const [formValues,setFormValues]=useState(state);
const [customerId,setnewreview] = useState("");
const [picture,setFile]=useState("");
const [texts,setText]=useState('');
const [imageUrl,setImageUrl]=useState('');
const [selectedOne,setSelectedOne]=React.useState("");
const [selectonOption,setSelectedOption]=useState(null);
const [userOption,setUserOption]=React.useState("");
const [check1,setCheck1]=useState(false);
const [male,setMale]=useState(false);
const [female,setFemale]=useState(false);
const [gender,setGender]=useState("");
const [selected,setSelected]=React.useState("");
// const genderMale = () => {
// setMale(true);
// setFemale(false);
// setGender("Male");
// }
// const genderFemale = () => {
// setMale(false);
// setFemale(true);
// setGender('Female');
// }

// const data = [
// {
// key:'1', value:'Mobiles', disabled:true},
// {key:'2', value:'Appliances'},
// {key:'3', value:'Cameras'},
// {key:'4', value:'Computers'},
// {key:'5', value:'Vegetables'},
// {key:'6', value:'Diary Products'},
// {key:'7', value:'Drinks'},
// ]
//   useEffect(()=> {
//     Axios.get("http://192.168.0.104:8000/registration/read").then((response) => {
//     //  console.log(response)
//     setFoodList(response.data)
//     alert( "+, add username & password for register")
//     })
//   },[])


// useEffect(() => {
//   const todoList = localStorage.getItem('email');

//   console.log(todoList, 'todoList');

//   if (todoList) {
//     setTodoList(JSON.parse(todoList));
//   }
// }, []);

// const addToList = () => {
//  console.log(email + password)
// const email=email;
// const password=password;
//    const submitreview = ()   => {

//     console.log(email,password)
//     Axios.post("http://192.168.0.104:8000/registration/insert",

//     {email:email,password:password}

//     )
//     .then(response=> console.log(response))
//   alert("+");
// }
// const updatereview = (data) => {
//   if(data){
//   alert("FOR ADMIN USE UPDATE,DELETE OPERATIONS")
//   }
//   console.log(data,email,password)
//     Axios.put("http://192.168.0.104:8000/registration/edit", {
//     customerId:customerId,
//   email: email,
//     password: password

//   }

// );

// setnewreview('')




// setnewlist("")
//   };

//  {email: email ,
//  password: password}

//   const handleClicks =
//     (e) => {setNewFoodName(e.target.value)}
//   const handleClick =
//     (event) => {setFoodList(event.target.value)}

//    console.log(addToList);
// }
// const handleAdd = () => {

//   if (textFieldValue.length > 0) {
//     let newToDoList = [...email];

//     newToDoList.push(textFieldValue);

//     setTodoList(newToDoList);
//     setTextFieldValue('');
  //clear 'toDoList' from local storage

  // localStorage.removeItem('newToDoList');

  // console.log(newToDoList);

  //save to local storage
//       localStorage.setItem('toDoList', JSON.stringify(newToDoList));


//   }
// };
// const deletereview = (data) =>  {

//   Axios.delete(`http://192.168.0.104:8000/registration/delete/${data}`,
//     {customerId:email}
//     )

//   };
useEffect(()=> {
  Axios.get("http://192.168.1.46:14400/registration/read").then((response) => {
  //  console.log(response)
  setFoodList(response.data)
  alert( "+, Please register-form Details")
  })
},[])


// useEffect(() => {
//   const todoList = localStorage.getItem('email');

//   console.log(todoList, 'todoList');

//   if (todoList) {
//     setTodoList(JSON.parse(todoList));
//   }
// }, []);

// const addToList = () => {
//  console.log(email + password)
// const email=email;
// const password=password;
const submitreview = async ()   => {
try{
const responses=await QRCode.toDataURL(texts);
setImageUrl(responses);
}
catch(error){
console.log(error);
}
//console.log(email,password,QRCode,Date,selected,check1,male,female)

console.log(QRCode,
  interestRates,
  nooftimesinterestcompoundperyear,
  principle,
  //amounts,
  //compoundInterest,
  principles
  )
Axios.post("http://192.168.1.46:14400/registration/inserts",

  //{email:email,password:password,QRCode:QRCode,Date:Date,selected,check1,male,female}
  {QRCode:QRCode,
    interestRates:interestRates,
    nooftimesinterestcompoundperyear:nooftimesinterestcompoundperyear,
    principle:principle,
    //amounts:amounts,
   // compoundInterest:compoundInterest
   
  }
  // picture:e.target.value}
  )
  .then(response=> console.log(response))
//alert("email value is saved to DATABASE==" + email + ',' + "password value is saved DATABASE==" + password + ',' + "selected is:" + selected + ',' + "detailed set:" + check1 + ',' + "Male is:" + male + ',' + "Female is:" + female + ',' + "Date is printed in DB," + "QRCode is saved to DATABASE")
alert( "QRCode value is saved to DATABASE," + interestRates + "%p.a" +"=" +"interestRates saved"+ "," + nooftimesinterestcompoundperyear + "=" + "nooftimesinterestcompoundperyear saved" + ',' + principle  + "=" + "principle saved")
let amounts=+(+principle)*
(1+(+interestRates/100))**
(+nooftimesinterestcompoundperyear)
alert("amounts=" +amounts)
let compoundInterest=+((+amounts)-(+principle))
 alert("compoundInterest=" +compoundInterest) 
}
//   const submitreview = (e:any)   => {

//     // console.log(email,password)

//     Axios.post("http://192.168.0.104:8001/registration/inserts",

//      {email:email,password:password,picture:e.target.value}

//      )
//      .then(response=> console.log(response))
//    alert("+");
//  }

const updatereview = () => {
//console.log(customerId,email,password)
console.log(customerId,QRCode,interestRates,nooftimesinterestcompoundperyear,principle,amounts,compoundInterest);
//alert("FOR ADMIN USE UPDATE,DELETE OPERATIONS")


  Axios.put("http://192.168.1.46:14400/registration/edit", {
  customerId:customerId,
 QRCode:QRCode,
      //Date:today
      interestRates:interestRates,
amounts:amounts,
compoundInterest:compoundInterest
}

);

setnewreview('')
//alert("email value is UPDATED to DATABASE==" +email + ',' + "password value is UPDATED DATABASE==" + password + ',' + "QRCode is UPDATED to DATABASE")
//alert( "QRCode value is UPDATED"+ "," + QRCode + "interestrate value is UPDATED " + interestRate + "value is UPDAYED" + ',' + nooftimesinterestcompoundperyear + "value is UPDATED" + ',' + ":nooftimesinterestcompoundperyear UPDATED" + ',' + principle + "principle UPDATED" + "," + amount + "amount UPDATED" + ',' + timeinyear + "timeinyear UPDATED");


// setnewlist("")
};

//  {email: email ,
//  password: password}

//   const handleClicks =
//     (e) => {setNewFoodName(e.target.value)}
//   const handleClick =
//     (event) => {setFoodList(event.target.value)}

//    console.log(addToList);
// }
// const handleAdd = () => {

//   if (textFieldValue.length > 0) {
//     let newToDoList = [...email];

//     newToDoList.push(textFieldValue);

//     setTodoList(newToDoList);
//     setTextFieldValue('');
  //clear 'toDoList' from local storage

  // localStorage.removeItem('newToDoList');

  // console.log(newToDoList);

  //save to local storage
//       localStorage.setItem('toDoList', JSON.stringify(newToDoList));


//   }
// };
const deletereview = () =>  {
console.log(customerId)
Axios.delete(`http://192.168.1.46:14400/registration/delete`,
  {data:{customerId:customerId}})

console.log({data:{customerId:customerId}})
//alert("email value is DELETED FROM DATABASE==" +email + ',' + "password value is DELETED FROM DATABASE==" + password + ',' + "QRCode is DELETED FROM DATABASE")
alert( "QRCode value is DELETED"+ "," + QRCode + "interestrate value is DELETED " + interestRates + "value is DELETED" + ',' + nooftimesinterestcompoundperyear + "value is DELETED" +  ',' + principle + "principle DELETED" + "," + amounts + "amount DELETED" + ',' + compoundInterest + "COMPOUNDINTEREST DELETED");

};



//     const deletereview = () => {
//       alert("FOR ADMIN USE UPDATE,DELETE OPERATIONS")

// console.log(email,password)
//   Axios.delete("http://192.168.0.103:8001/registration/delete", {
//   customerId:customerid,
// email: email,
//   password: password

// }

// );
//     }





// setnewlist("")




return (
<View style={styles.container} >
{/* <Text>APPLET IS VIEWING IN UBUNTU WITH DIFFERENT FEATURES!</Text> */}
{/* <TextInput
      style={styles.input}

    placeholder="ENTER EMAIL"
    onChangeText=
   {(text) => setEmail(text)}
// {handleAdd}
/> */}
{/* <Button

    title="TOGGLE BUTTON"

  />  */}
{/* <button>SUBMIT</button> */}
{/* <StatusBar style="auto" />  */}
<SafeAreaView style={styles.container}>
{/* <Text>    SIMPLE REGISTER-FORM</Text> */}
    <Text>COMPOUND AMOUNT CALCULATOR</Text>
<Text> -------------------------------------------------------</Text>
<View>
{/* <CheckBox
title="Male"
checked={male}
checkedIcon="dot-circle-o"
uncheckedIcon="circle-o"
onPress={genderMale}
/> */}
{/* <CheckBox
title="Female"
checked={female}
checkedIcon="dot-circle-o"
uncheckedIcon="circle-o"
onPress={genderFemale}
/> */}
</View>
<View>
{/* <CheckBox
center
title="Detailed Check"
checked={check1}
onPress={() => setCheck1(!check1)}
/> */}
</View>
<View>
{/* <SelectList
setSelected={(val) => setSelected(val)}
data={data}
save="value"
/> */}
</View>
<TextInput style={styles.input} placeholderTextColor={"red"} placeholder=" AUTOMATIC : QRCODEFILL" onChangeText={(texts) => setText(texts)} />
{imageUrl ? (
  <a href={imageUrl} download>
    <img src={imageUrl} alt="img" />
  </a>
): null}
{/* <TextInput
      style={styles.input}
    placeholderTextColor={"red"}
    placeholder="ENTER EMAIL ADDRESS PLZ"
    onChangeText=
  {(text) => setEmail(text)}
           /> */}
           <TextInput
      style={styles.input}
    placeholderTextColor={"red"}
    placeholder="ENTER NO:TIMES (I.C/YEAR)"
    onChangeText=
  {(text) => setNooftimesinterestcompoundperyear(text)}
           />
{/* <TextInput
  style={styles.input}
  placeholderTextColor={"red"}
    // onChangeText={onChangeNumber}
  placeholder="ENTER(CONFIRM)PASSWORD "
        onChangeText= {(text) =>
     setPassword(text)}
/> */}

{/* <TextInput
  style={styles.input}
  placeholderTextColor={"red"}
    // onChangeText={onChangeNumber}
  placeholder="ENTER COMPOUND AMOUNT"
        onChangeText= {(text) =>
     setAmounts(text)}
/> */}
<TextInput
  style={styles.input}
  placeholderTextColor={"red"}
    // onChangeText={onChangeNumber}
  placeholder="ENTER PRINCIPLE AMOUNT"
        onChangeText= {(text) =>
     setPrinciple(text)}
/>
 <TextInput
  style={styles.input}
  placeholderTextColor={"red"}
    // onChangeText={onChangeNumber}
  placeholder="ENTER INTERESTRATES%p.a"
        onChangeText= {(text) =>
     setInterestRates(text)}
/>
    <Button
    color='red'
    title="TOGGLE BUTTON"
  onPress={submitreview}
  />
   <TextInput
  style={styles.input}
    // onChangeText={onChangeNumber}
    placeholderTextColor={"red"}
    placeholder="CONTACT ADMINISTRATOR"
  onChangeText= {(text) =>
    setnewreview(text)}
/>


<Button
  title="BUTTON UPDATE"
 onPress={updatereview}
  />

   <Button

    title="BUTTON DELETE"
       onPress={deletereview}
  />

  {/* <input type="file" name="myimage/*" onChange={submitreview}/> */}
</SafeAreaView>

</View>
);
}

const styles = StyleSheet.create({
container: {
flex: 1,
backgroundColor: 'pink',
alignItems: 'center',
justifyContent: 'center',
},
  fixToText: {
    top:'600%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

// });
input:
{
top:'-0.4%',
backgroundColor:'yellow',
alignItems:"center",
height: 40,
margin: 12,
borderWidth: 1,
padding: 10,
},
put:
{
top:'200%',
alignItems:"center",
height: 40,
width:20,
margin: 12,
borderWidth: 1,
padding: 10,
},
});
//export default TabOneScreen;
